'use client';
import { GlobalStyle, CustomControlledMenu } from './MenuStyles';
import { useMenu } from './useMenu';
import { MenuItem } from '@szhsin/react-menu';
import Link from 'next/link';
import Image from 'next/image';
import PopForm from '@/app/_components/Form/PopForm';
import NavigationMenu from './MenuItems';
import styles from './styles/Menu.module.css';
import '@szhsin/react-menu/dist/index.css';
import PopFormStyles from './styles/PopForm.module.css';
import VideoMenuItem from './VideoMenuItem';

const ClientMenu = ({ PackMenuItems, IndMenuItems, SupportsMenuItems, VideoCatMenuItems }: any) => {
    const {
        //prettier-ignore
        ref: packagingRef,
        menuState: packagingMenuState,
        toggleMenu: togglePackagingMenu,
        anchorProps: packagingAnchorProps,
        hoverProps: packagingHoverProps,
    } = useMenu();
    const {
        //prettier-ignore
        ref: industryRef,
        menuState: industryMenuState,
        toggleMenu: toggleIndustryMenu,
        anchorProps: industryAnchorProps,
        hoverProps: industryHoverProps,
    } = useMenu();
    const {
        //prettier-ignore
        ref: supportRef,
        menuState: supportMenuState,
        toggleMenu: toggleSupportMenu,
        anchorProps: supportAnchorProps,
        hoverProps: supportHoverProps,
    } = useMenu();
    const {
        //prettier-ignore
        ref: videoRef,
        menuState: videoMenuState,
        toggleMenu: toggleVideoMenu,
        anchorProps: videoAnchorProps,
        hoverProps: videoHoverProps,
    } = useMenu();

    const limitedPackagingMenuItems = PackMenuItems.slice(0, 12);
    const limitedIndustryMenuItems = IndMenuItems.slice(0, 12);
    const limitedSupportMenuItems = SupportsMenuItems.slice(0, 12);
    const limitedVideoCatMenuItems = VideoCatMenuItems.slice(0, 12);

    const generateMenuItems = (items: { label: string; href?: string }[]) => {
        return items.map((item, index) => <MenuItem key={index}>{item.href ? <Link href={item.href}>{item.label}</Link> : <span>{item.label}</span>}</MenuItem>);
    };

    const generateVideoCatMenuItems = (items: { Title: string }[]) => {
        return items.map((item, index) => <VideoMenuItem key={index} title={item.Title} />);
    };

    return (
        <>
            <GlobalStyle />
            <div className={styles.navContainer}>
                <div className={styles.navHeader}>
                    <div className={styles.navLogo}>
                        <Link href="/">
                            <Image src="/images/logo.png" alt="SUNMELL" width={118} height={18} priority unoptimized />
                        </Link>
                    </div>
                    <NavigationMenu
                        //prettier-ignore
                        packagingRef={packagingRef}
                        packagingAnchorProps={packagingAnchorProps}
                        industryRef={industryRef}
                        industryAnchorProps={industryAnchorProps}
                        supportRef={supportRef}
                        supportAnchorProps={supportAnchorProps}
                        videoRef={videoRef}
                        videoAnchorProps={videoAnchorProps}
                        styles={styles}
                    />
                    <PopForm data={`get quote`} styles={PopFormStyles} />
                </div>

                <CustomControlledMenu
                    //prettier-ignore
                    {...packagingHoverProps}
                    {...packagingMenuState}
                    anchorRef={packagingRef}
                    onClose={() => togglePackagingMenu(false)}
                >
                    <div className={styles.column}>{generateMenuItems(limitedPackagingMenuItems.slice(0, 4))}</div>
                    <div className={styles.divider}></div>
                    <div className={styles.column}>{generateMenuItems(limitedPackagingMenuItems.slice(4, 8))}</div>
                    <div className={styles.divider}></div>
                    <div className={styles.column}>{generateMenuItems(limitedPackagingMenuItems.slice(8, 12))}</div>
                </CustomControlledMenu>

                <CustomControlledMenu
                    //prettier-ignore
                    {...industryHoverProps}
                    {...industryMenuState}
                    anchorRef={industryRef}
                    onClose={() => toggleIndustryMenu(false)}
                >
                    <div className={styles.column}>{generateMenuItems(limitedIndustryMenuItems.slice(0, 4))}</div>
                    <div className={styles.divider}></div>
                    <div className={styles.column}>{generateMenuItems(limitedIndustryMenuItems.slice(4, 8))}</div>
                    <div className={styles.divider}></div>
                    <div className={styles.column}>{generateMenuItems(limitedIndustryMenuItems.slice(8, 12))}</div>
                </CustomControlledMenu>

                <CustomControlledMenu
                    //prettier-ignore
                    {...supportHoverProps}
                    {...supportMenuState}
                    anchorRef={supportRef}
                    onClose={() => toggleSupportMenu(false)}
                >
                    <div className={styles.column}>{generateMenuItems(limitedSupportMenuItems.slice(0, 4))}</div>
                    <div className={styles.divider}></div>
                    <div className={styles.column}>{generateMenuItems(limitedSupportMenuItems.slice(4, 8))}</div>
                    <div className={styles.divider}></div>
                    <div className={styles.column}>{generateMenuItems(limitedSupportMenuItems.slice(8, 12))}</div>
                </CustomControlledMenu>

                <CustomControlledMenu
                    //prettier-ignore
                    {...videoHoverProps}
                    {...videoMenuState}
                    anchorRef={videoRef}
                    onClose={() => toggleVideoMenu(false)}
                >
                    <div className={styles.column}>{generateVideoCatMenuItems(limitedVideoCatMenuItems.slice(0, 4))}</div>
                    <div className={styles.divider}></div>
                    <div className={styles.column}>{generateVideoCatMenuItems(limitedVideoCatMenuItems.slice(4, 8))}</div>
                    <div className={styles.divider}></div>
                    <div className={styles.column}>{generateVideoCatMenuItems(limitedVideoCatMenuItems.slice(8, 12))}</div>
                </CustomControlledMenu>
            </div>
        </>
    );
};

export default ClientMenu;
