import React from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { MenuItem } from '@szhsin/react-menu';

interface VideoMenuItemProps {
    title: string;
}

const VideoMenuItem: React.FC<VideoMenuItemProps> = ({ title }) => {
    const router = useRouter();
    const searchParams = useSearchParams();

    const handleClick = () => {
        const params = new URLSearchParams(searchParams.toString());
        params.set('category', title); // 设置 category 查询参数
        params.delete('page'); // 重置分页，防止停留在较高的页码上
        router.push(`/video?${params.toString()}`);
    };

    return (
        <MenuItem onClick={handleClick}>
            <span>{title}</span>
        </MenuItem>
    );
};

export default VideoMenuItem;
