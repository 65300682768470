interface ImageField {
    data: {
        id: number;
        attributes: {
            url: string;
            alternativeText: string | null;
            width: number;
            height: number;
            placeholder: string | null;
            formats?: {
                thumbnail?: {
                    url: string;
                };
            };
        };
    };
}

const assetsBaseUrl = process.env.NEXT_PUBLIC_STRAPI_URL;

export const imageReducer = (imageField: ImageField) => {
    const fields = imageField.data.attributes;
    return {
        url: `${assetsBaseUrl}${fields.url}`,
        alt: fields.alternativeText ?? '',
        width: fields.width,
        height: fields.height,
        blurDataURL: `${assetsBaseUrl}${fields.formats?.thumbnail?.url}`,
    };
};

export function getStrapiURL(path = '') {
    const baseUrl = process.env.NEXT_PUBLIC_STRAPI_URL;
    return `${baseUrl}${path}`;
}

export function getStrapiMedia(url: string | null) {
    if (url == null) return null;
    if (url.startsWith('data:')) return url;
    if (url.startsWith('http') || url.startsWith('//')) return url;
    return `${getStrapiURL()}${url}`;
}
