interface NavItem {
    key: string;
    label: string;
    link: string;
    dropdown?: Array<{ key: string; label: string; link: string }>;
}

export const navItems: NavItem[] = [
    { key: 'packaging_by_style', label: 'Packaging by Style', link: '/packaging_by_style' },
    { key: 'industry_solution', label: 'Industry Solution', link: '/industry_solution' },
    { key: 'about', label: 'About us', link: '/about' },
    { key: 'support', label: 'Support', link: '/support' },
    { key: 'blog', label: 'Blog', link: '/blog' },
    { key: 'video', label: 'Video', link: '/video' },
    { key: 'contact', label: 'Contact', link: '/contact' },
];
