import { useRef } from 'react';
import { useHover, useMenuState } from '@szhsin/react-menu';

export const useMenu = () => {
    const ref = useRef(null);
    const [menuState, toggleMenu] = useMenuState();
    const { anchorProps, hoverProps } = useHover(menuState.state, toggleMenu, { openDelay: 0, closeDelay: 0 });

    return { ref, menuState, toggleMenu, anchorProps, hoverProps };
};
