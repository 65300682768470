'use client';
import React from 'react';
import { Modal, ModalContent, ModalBody, Button, useDisclosure } from '@nextui-org/react';

import { Form } from '@/app/_components/Form/Form';
import FormStyles from './styles/Form.module.css';

export default function PopForm({ data, styles }: { data: any; styles: any }) {
    // console.dir(styles);
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Button className={styles.ClickButton} onPress={onOpen}>
                {data}
            </Button>
            <Modal
                //prettier-ignore
                isOpen={isOpen}
                onClose={onClose}
                size="2xl"
                classNames={{
                    body: 'py-10',
                }}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalBody>
                                <div className={styles.Header}>
                                    <p>Request A Quote</p>
                                </div>
                                <Form styles={FormStyles} onSuccess={onClose} />
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
