import styled, { createGlobalStyle } from 'styled-components';
import { ControlledMenu, MenuButton } from '@szhsin/react-menu';
import { menuSelector, menuItemSelector } from '@szhsin/react-menu/style-utils';

export const GlobalStyle = createGlobalStyle`
  .szh-menu-container {
    position: absolute !important;
    width: 100% !important;
    background-color: #ffffff !important;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #dddddd;
    }
  }

  .szh-menu {
    margin: 0 !important;
    position: absolute !important;
    left: 0 !important;
    top: 100% !important;
  }

  .szh-menu > li[aria-hidden="true"] {
    display: none !important;
  }
`;
export const CustomMenuButton = styled(MenuButton)`
    color: #f1f1f1;
    padding: 0 1.5rem;
`;
export const CustomControlledMenu = styled(ControlledMenu)`
    ${menuSelector.name} {
        border-radius: 0;
        box-shadow: none;
        padding: 1.5rem 0;
        background-color: #ffffff;
        display: flex;
        box-sizing: border-box;
        overflow-x: hidden;
        position: relative !important;
        width: 100%;
        max-width: 1316px;
        margin: 0 auto !important;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #dddddd;
        }
    }

    ${menuItemSelector.name} {
        cursor: pointer;
        color: #1d1d1f;
        display: block;
        padding: 0;
        margin-bottom: 0.5rem;

        a,
        span {
            display: block;
            padding: 0.5rem 0.5rem;
        }
    }

    ${menuItemSelector.hover} {
        color: #00b6ff;
        background-color: transparent;
    }

    ${menuItemSelector.disabled} {
        color: #cccccc;
    }
`;
