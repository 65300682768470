'use client';

import NavbarMobile from '@/app/_components/Navbar/NavbarMobile';
import { Navbar, NavbarBrand, Link } from '@nextui-org/react';
import React, { useCallback, useState } from 'react';
import Image from 'next/image';
import styles from './styles/navbar.module.css';

export default function MobileMenu() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuOpenChange = useCallback((isOpen: boolean) => {
        setIsMenuOpen(isOpen);
    }, []);

    return (
        <>
            <Navbar
                // isBordered
                isMenuOpen={isMenuOpen}
                onMenuOpenChange={handleMenuOpenChange}
                className={`${styles.navbar} ${isMenuOpen ? styles.navbarOpen : ''}`}
                classNames={{
                    item: ['flex', 'relative', 'h-full', 'items-center', "data-[active=true]:after:content-['']", 'data-[active=true]:after:absolute', 'data-[active=true]:after:bottom-0', 'data-[active=true]:after:left-0', 'data-[active=true]:after:right-0', 'data-[active=true]:after:h-[2px]', 'data-[active=true]:after:rounded-[2px]', 'data-[active=true]:after:bg-black'],
                }}
            >
                <NavbarBrand className="justify-start">
                    <Link href="/">
                        <div className={styles.logo}>
                            <Image
                                // prettier-ignore
                                src="/images/logo.png"
                                alt="SUNMELL"
                                width={118}
                                height={18}
                                className={styles.logoImage}
                                priority
                                unoptimized
                            />
                        </div>
                    </Link>
                </NavbarBrand>
                <NavbarMobile isMenuOpen={isMenuOpen} handleMenuOpenChange={handleMenuOpenChange} />
            </Navbar>
        </>
    );
}
