import(/* webpackMode: "eager" */ "/next.js/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/_components/Bottom/styles/bottom.module.css");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/_components/Bottom/styles/Form.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["Form"] */ "/next.js/src/app/_components/Form/Form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/next.js/src/app/_components/ReactMenu/ClientMenu/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/next.js/src/app/_components/ReactMenu/MobileMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/next.js/src/app/_components/ReactMenu/sticky.tsx");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/next.js/src/app/providers.tsx");
