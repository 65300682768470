'use client';
import StickyBox from 'react-sticky-box';

export default function StickyPage({ children }: any) {
    return (
        <StickyBox offsetTop={0} offsetBottom={40} style={{ zIndex: 1000 }}>
            {children}
        </StickyBox>
    );
}
