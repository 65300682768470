import React from 'react';
import Link from 'next/link';
import { CustomMenuButton } from './MenuStyles';

const NavigationMenu = ({
    //prettier-ignore
    packagingRef,
    packagingAnchorProps,
    industryRef,
    industryAnchorProps,
    supportRef,
    supportAnchorProps,
    videoRef,
    videoAnchorProps,
    styles,
}: any) => {
    return (
        <div className={styles.menuItems}>
            <Link href="/packaging_by_style" className={styles.menuLink}>
                <div ref={packagingRef} {...packagingAnchorProps} className={styles.menuButton}>
                    <CustomMenuButton className={styles.customMenuButton} onClick={(e) => e.stopPropagation()}>
                        Packaging by Style
                    </CustomMenuButton>
                </div>
            </Link>
            <Link href="/industry_solution" className={styles.menuLink}>
                <div ref={industryRef} {...industryAnchorProps} className={styles.menuButton}>
                    <CustomMenuButton className={styles.customMenuButton} onClick={(e) => e.stopPropagation()}>
                        Industry Solution
                    </CustomMenuButton>
                </div>
            </Link>
            <Link href="/about" className={styles.menuLink}>
                <div className={styles.menuButton}>
                    <CustomMenuButton className={styles.customMenuButton}>About us</CustomMenuButton>
                </div>
            </Link>
            <Link href="/blog" className={styles.menuLink}>
                <div className={styles.menuButton}>
                    <CustomMenuButton className={styles.customMenuButton}>Blog</CustomMenuButton>
                </div>
            </Link>
            <div ref={supportRef} {...supportAnchorProps} className={styles.menuButton}>
                <CustomMenuButton className={styles.customMenuButton} onClick={(e) => e.stopPropagation()}>
                    Support
                </CustomMenuButton>
            </div>
            <Link href="/video" className={styles.menuLink}>
                <div ref={videoRef} {...videoAnchorProps} className={styles.menuButton}>
                    <CustomMenuButton className={styles.customMenuButton} onClick={(e) => e.stopPropagation()}>
                        Video
                    </CustomMenuButton>
                </div>
            </Link>
            <Link href="/contact" className={styles.menuLink}>
                <div className={styles.menuButton}>
                    <CustomMenuButton className={styles.customMenuButton}>Contact</CustomMenuButton>
                </div>
            </Link>
        </div>
    );
};

export default NavigationMenu;
